<template>
  <b-row class="justify-content-center">
    <b-col md="6">
      <b-card>
        <b-form v-on:submit.prevent>
          <b-form-group label="Unit Name" label-for="unit_name">
            <b-form-input
              id="unit_name"
              placeholder="Unit Name"
              v-model="form.unit_name"
              required
            />
          </b-form-group>
          <b-row>
            <!-- submit and reset -->
            <b-col
              md="6"
              class="mt-50"
              v-if="permissions.includes('product-unit-update')"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                @click="updateUnits()"
                block
              >
                Update
              </b-button>
            </b-col>
            <b-col
              md="6"
              class="mt-50"
              v-if="permissions.includes('product-unit-delete')"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="danger"
                @click="deleteUnits()"
                block
              >
                Delete
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ToastificationContent,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        unit_name: "",
      },
      permissions: [],
      errors: "",
      errMessage: "",
    };
  },
  created() {
    this.getAllPermissions();
    this.getDetails();
  },
  methods: {
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getDetails() {
      const id = this.$route.params.id;
      this.$http
        .get("product/unit/" + id)
        .then((response) => {
          this.form.unit_name = response.data.data.name;
          // console.log(response.data.data)
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    updateUnits() {
      const id = this.$route.params.id;
      this.$http
        .post("product/unit/" + id + "/update", {
          name: this.form.unit_name,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Success update Unit",
              variant: "success",
            },
          });
          location.href = "/product/units";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    deleteUnits() {
      const id = this.$route.params.id;
      this.$http
        .post("product/unit/" + id + "/delete")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Unit has been deleted",
              variant: "danger",
            },
          });
          location.href = "/product/units";
          console.log(response.data.data);
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          console.log(errors.response);
        });
    },
  },
};
</script>